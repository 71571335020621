<template>
  <app-quest :item-id="itemId" :params="params" :cmp-standings="Standings" />
</template>

<script>
import AppQuest from "@/components/event/Content/Quest/Quest";
import Standings from "./Standings";
import _ from "lodash";

export default {
  name: "ZinforoQuest",
  props: ["item-id", "params"],
  data() {
    return {
      Standings,
    };
  },
  components: {
    AppQuest,
  },
  computed: {
    mode() {
      return _.get(this.params, "mode");
    },
    isStandings() {
      return this.mode === "standings";
    },
  },
};
</script>